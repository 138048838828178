
$(document).ready(function () {

    $('#mobile').click(function(){

        $(this).toggleClass('active');
        $('.mobileMenu').toggleClass('active');
        $('.overlay').toggleClass('active');

    });

    $('.overlay').click(function(){
        $(this).toggleClass('active');
        $('#mobile').toggleClass('active');
        $('.mobileMenu').toggleClass('active');
    });

    $('.aboutLink').on('click', function (e) {
      //e.preventDefault();
      $('html, body').animate({
        scrollTop: $("#about").offset().top
      }, '300');
    }); 

    $('.variantItem').click(function () {
        var answer = $(this).attr('answer');
        $.post('/',{
            answer: answer
        },function(){
            document.location.reload(true);
        });
    });

    $('.buttonBack').click(function () {
        $.post('/',{
            answer: ''
        },function(){
            document.location.reload(true);
        });
    });

});

